<template>
    <div v-if="info === 'primary'" class="info-box info-box--primary">
        {{text}}
    </div>
    <div v-if="info === 'secondary'" class="info-box info-box--secondary">
        {{text}}
    </div>
    <div v-if="info === 'warning'" class="info-box info-box--warning">
        {{text}}
    </div>
    <div v-if="info === 'danger'" class="info-box info-box--danger">
        {{text}}
    </div>
    <div v-if="info === 'error'" class="info-box info-box--error">
        {{text}}
    </div>
</template>
<script>
export default {
    name: "InfoBox",
    props: {
        info: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        }
    },
}
</script>
<style lang="scss" scoped>
    .info-box {
        padding: 0 10px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        color: #344051;
        display: inline-flex;
        align-items: center;
        gap: 6px;
        height: 28px;

        &--primary {
            background-color: #F0F9FF;
            color: #0B4A6F;
        }
        &--secondary {
            background-color: #ECFDF5;
            color: #064E3B;
        }
        &--warning {
            background-color: #FFFBEB;
            color: #78350F;
        }
        &--danger {
            background-color: #FFE5E5;
            color: #790C0C;
        }
        &--error {
            background-color: #f5afaf;
            color: #e70909;
        }
    }
</style>