<template>
  <div class="main-wrapper">
    <div class="header o-flex o-flex--center o-flex--justify">
        <router-link :to="{name: 'employee'}">
          <div class="header__logo">
            <img src="@/assets/img/logo-workforce.svg" alt="" />
          </div>
        </router-link>
        <div 
          class="menuBtn"
          @click="trigger"
          :class="toggleMenu ? 'is-active' : ''"
        >
          <img src="@/assets/img/sidebar-collapse.svg" alt="" />
        </div>
    </div>
    <div class="backdrop" @click="toggleMenu = false" :class="toggleMenu ? 'is-active' : ''"></div>
    <Aside :class="toggleMenu ? 'show' : ''" />
    <slot></slot>
  </div>
</template> 
<script>
import Aside from '../components/Aside/Aside.vue';

export default {
  components: {
    Aside,
  },

  data() {
    return {
      toggleMenu: false,
    };
  },

  methods: {
    trigger() {
      this.toggleMenu = !this.toggleMenu;
    }
  },
};
</script>
<style lang="scss" scoped>
.main-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: var(--aside-width);

  @media(max-width: 1200px) {
    padding-left: 212px;
  }

  @media(max-width: 992px) {
    padding-left: 0;
  }
}

.header {
  background: var(--white);
  padding: 0 16px;
  min-height: 62px;
  position: relative;
  // z-index: 30;
  box-shadow: 0px 1px 3px 0px rgba(20, 28, 37, 0.08);
  display: none;

  @media(max-width: 992px) {
    display: flex;
  }

  &__logo {
    max-width: 145px;
  }
}

.menuBtn {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  z-index: 31;

  &.is-active {
    transform: rotate(180deg);
    top: -2px;
  }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 500ms;
    opacity: 0;
    pointer-events: none;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.4);

    &.is-active {
      opacity: 1;
      pointer-events: auto;
    }
}
</style>
