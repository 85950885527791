<template>
    <div class="card h-100" v-if="loadPage">
        <div class="card_body h-100">
            <Loader/>
        </div>
    </div>
    <Form v-else :validation-schema="schema">
        <div class="card">
            <div class="card__header o-flex o-flex--center o-flex--justify">
                <div class="flag">
                    <div class="flag__icon">
                        <router-link :to="{name: 'project-view-edit', params: { id: backLink }}" class="button button--square">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 5L7.5 10L12.5 15" stroke="#141C25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </router-link>
                    </div> 
                    <div class="flag__content">
                        <slot name="heading"></slot>
                    </div>
                </div>
                <a href="javascript:;" v-if="accordion" class="accordion-trigger" @click="accordionActive = !accordionActive" :class="{'active': accordionActive}">
                    <svg fill="none" width="16" viewBox="0 0 16 16">
                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m4 10 4-4 4 4"/>
                    </svg>
                </a>
            </div>
            <transition name="fade">
                <div class="card__body" v-if="accordionActive">
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Name <span>*</span></p>
                                <div class="relative">
                                    <Field type="text" name="name" class="input input--primary" :placeholder="'Enter'" v-model="name"/>
                                    <ErrorMessage class="text-error" name="name"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Start Date <span>*</span></p>
                                <div class="relative">
                                    <Field name="startDate" v-slot="{ field }">
                                        <vue-date-picker
                                            :enable-time-picker="false"
                                            auto-apply
                                            v-bind="field"
                                            :model-type="'yyyy-MM-dd'"
                                            :format="'dd.MM.yyyy'"
                                            placeholder="Select Date"
                                            v-model="startDate"
                                            :ui="{ input: 'date-picker' }"
                                        >
                                            <template #input-icon>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </template>
                                        </vue-date-picker>
                                    </Field>
                                    <ErrorMessage class="text-error" name="startDate"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>End Date</p>
                                <div class="relative">
                                    <Field name="endDate" v-slot="{ field }">
                                        <vue-date-picker 
                                        auto-apply
                                        v-bind="field"
                                        :enable-time-picker="false"
                                        :model-type="'yyyy-MM-dd'"
                                        :format="'dd.MM.yyyy'"
                                        placeholder="Select Date"
                                        v-model="endDate"
                                        name="endDate"
                                        :ui="{ input: 'date-picker' }"
                                        :disabled="!startDate || ongoingStatus"
                                        :min-date="minEndDate"
                                        >
                                            <template #input-icon>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </template>                                        
                                        </vue-date-picker>
                                    </Field>
                                    <ErrorMessage class="text-error" name="endDate"/>
                                </div>
                                 <div class="check-box mt-">
                                    <input id="ongoing" type="checkbox" v-model="ongoingStatus" @change="ongoingChecked">
                                    <label for="ongoing">
                                        <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                        </div>
                                        <p class="check-text">Ongoing</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xs-12 col-md-6 col-lg-1 o-flex o-flex--center">
                            
                        </div> -->
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Status <span>*</span></p>
                                <div class="relative">
                                    <VueMultiselect name="teamLead" v-model="statusSelected" placeholder="Select one" :options="statuses" label="name" track-by="name"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="skill-list">
                        <h2 class="u-font-600">Required Skills</h2>

                        <div class="row row--end" v-for="(skill, index) in requiredSkills" :key="index">
                            <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="input-box">
                                    <p>Skill</p>
                                    <div class="relative">
                                        <VueMultiselect v-model="skill.skillSelected" placeholder="Select one" :options="availableSkills" label="name" track-by="id"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-3 col-lg-2 col-xl-2">
                                <div class="input-box">
                                    <p>Hours</p>
                                    <div class="relative">
                                        <InputNumber :hour="skill.hours" :max="4" @number="(value) => number(value, index)" :disabled="ongoingStatus"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-2 col-lg-2 col-xl-1 o-flex o-flex--start">
                                <div class="o-flex gap-10 button-wrapper">
                                    <a href="javascript:;" @click="addRow" v-if="index === requiredSkills.length - 1 && requiredSkills.length < topSkills.length" class="button button--square">
                                        <svg fill="none" width="20" viewBox="0 0 20 21">
                                            <path stroke="#344051" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 10.357h5m5 0h-5m0 0v-5m0 5v5"/>
                                        </svg>
                                    </a>
                                    <a href="javascript:;" @click="deleteRow(index)" class="button button--square" v-if="requiredSkills.length > 1">
                                        <svg fill="none" width="17" viewBox="0 0 18 19">
                                            <path stroke="#344051" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m15.667 6.857-1.663 9.455a1.667 1.667 0 0 1-1.641 1.378H5.637c-.809 0-1.501-.581-1.641-1.378L2.333 6.857m14.167-2.5h-4.688m0 0V2.69c0-.92-.746-1.667-1.666-1.667H7.854c-.92 0-1.667.746-1.667 1.667v1.667m5.625 0H6.189m-4.688 0h4.688"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade">
                <div class="card__footer card--border o-flex o-flex--justify o-flex--row-reverse o-flex--wrap" v-if="accordionActive">
                    <div class="card__btns o-flex o-flex--center gap-10">
                        <router-link :to="{name: 'project-view-edit', params: { id: backLink }}" class="button button--secondary">Cancel</router-link>
                        <button type="button" @click="submit" class="button button--primary" :disabled="working || disabledButton">Save Changes</button>
                    </div>
                    <button type="button" v-if="payloadCheck" @click="$emit('delete')" class="button button--delete">Delete</button>
                </div>
            </transition>
        </div>
    </Form>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueMultiselect from 'vue-multiselect';
import Loader from '../../components/Loader/Loader.vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { getTopSkills } from '../../service/general';
import * as yup from 'yup';
import { status } from '@/utilities/status';
import moment from "moment";
import InputNumber from '@/components/InputNumber/InputNumber.vue'

export default {
    name: "PhaseForm",
    components: {
        Field,
        Form,
        ErrorMessage,
        VueDatePicker,
        VueMultiselect,
        Loader,
        InputNumber,
    },
    emits: ['loading', 'formData', 'delete'],
    props: {
        working: {
            type: Boolean,
            default: false,
        },
        payload: {
            type: Object,
            default: {},
        },
        accordion: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadPage: true,
            accordionActive: true,
            name: '',
            startDate: null,
            endDate: null,
            ongoingStatus: false,
            statusSelected: null,
            allStatuses: [],
            topSkills: [],
            requiredSkills: [
                {
                    skillSelected: null,
                    hours: '',
                    ongoing: false,
                },
            ],

            schema: yup.object({
                name: yup
                .string()
                .required('Name is required')
                .min(2, 'Must be at least 2 characters'),
                startDate: yup
                .date()
                .nullable()
                .required('Start date is required'),
            }),
        }
    },

    computed: {
        availableSkills() {
            const selectedSkillIds = this.requiredSkills
                .map(skill => skill.skillSelected?.id) 
                .filter(id => id !== undefined); 
            return this.topSkills.filter(skill => !selectedSkillIds.includes(skill.id));
        },
        backLink() {
            return localStorage.getItem('projectId');
        },
        disabledButton() {
            return this.name === '' || this.startDate === null || this.statusSelected === null;
        },
        payloadCheck() {
            return this.payload && Object.keys(this.payload).length > 0;
        },
        statuses() {
            return status.filter(item => 
                item.name === "not-started" || 
                item.name === "in-progress" || 
                item.name === "done"
            );
        },
        minEndDate() { 
            if (!this.startDate) return null;
            const startDate = new Date(this.startDate);
            startDate.setDate(startDate.getDate() + 1);
            return startDate.toISOString().split('T')[0];
        },
    },

    methods: { 

        ongoingChecked() {
            if (this.ongoingStatus) {
                this.endDate = null;
            }
        },

        number(value, index) {
            this.requiredSkills[index].hours = value;
        },
        
        updateData(payload) {
            if (payload && Object.keys(payload).length > 0) {
                if (payload.name !== '') this.name = payload.name;
                if (payload.start_date !== null) this.startDate = moment(payload.start_date ).format('YYYY-MM-DD');
                if (payload.end_date !== null) this.endDate = moment(payload.end_date ).format('YYYY-MM-DD');
                if (payload.skills && payload.skills[0]?.pivot) {
                    this.ongoingStatus = payload.skills[0].pivot.ongoing === 1;
                }
                if (payload.status !== '') {
                    const selectedStatus = status.find(s => s.name === payload.status);
                    if (selectedStatus) {
                        this.statusSelected = { id: selectedStatus.id, name: selectedStatus.name };
                    }
                }
                if (payload.skills && Array.isArray(payload.skills)) {
                    if (payload.skills.length > 0) {
                        this.requiredSkills = payload.skills.map(skill => ({
                            skillSelected: skill || null,
                            hours: String(skill.pivot?.hours || ''),
                            ongoing: String(skill.pivot?.ongoing === 1 ? true : false),
                        }));
                    } else {
                        this.requiredSkills = [
                            {
                                skillSelected: null,
                                hours: '',
                                ongoing: false
                            },
                        ];
                    }
                }
            }

            this.loadPage = false;
            this.$emit('loading', false);
        },

        addRow() {

            const defaultSkill = {
                skillSelected: null,
                hours: null,
                ongoing: false,
            };

            this.requiredSkills.push(defaultSkill);
        },

        deleteRow(index) {
            this.requiredSkills.splice(index, 1);
        },

        async submit() {
            const skillsList = this.requiredSkills
            .filter(item => item.skillSelected !== null)
            .map(item => ({
                id: item.skillSelected.id,
                hours: item.hours,
                ongoing: this.ongoingStatus,
            }));

            const payload = {
                ...(this.name && { name: this.name }),
                ...(this.startDate && { start_date: this.startDate }),
                ...(this.endDate && { end_date: this.endDate }),
                ...(this.statusSelected && this.statusSelected.name && { status: this.statusSelected.name }),
                ...(skillsList && skillsList.length > 0 && { skills: skillsList }),
            }

            this.$emit('formData', payload);
        }
    },
    async created() {
        const [topSkills]  = await Promise.all([getTopSkills()])
        this.topSkills = topSkills;
        this.updateData(this.payload);
    },
}
</script>
<style lang="scss" scoped>

    .button-wrapper {
        // margin-top: 29px;
        margin-bottom: 16px;
    }

    .skill-list {
        margin-top: 24px;
    }

    .card__header {
        padding: 9px 0;

        &.active {
            padding-bottom: 9px;
            border-bottom: none;
        }
    }

    .card__body {
        padding: 24px 0;
        margin-top: 10px;
        border-top: 1px solid #E4E7EC;
    }

    .card__footer {
        padding-top: 24px;
        padding-bottom: 9px;
        gap: 10px;
    }

    .accordion-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(21, 28, 36, 0.05);
        background-color: #141C25;
        svg {
            transform: rotate(180deg);
            transition: all .3s ease;
        }

        &:hover {
            background-color: darken(#141C25, 10%);
        }
        
        &.active {
            svg {
                transform: rotate(0deg);
            }
        }
    }

</style>