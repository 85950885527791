<template>
    <div>
        AvailableResources
    </div>
</template>
<script>
export default {
    name:"AvailableResources",
}
</script>
<style lang="scss" scoped>
    
</style>