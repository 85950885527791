<template>
    <div class="upload-image-wrapper">
        <input ref="photo" @change="setPhoto" type="file" id="upload-photo">

        <label class="upload-image" for="upload-photo" :style="'background-image: url(' + photoPreview + ')'">
            <div class="upload-image-overlay">
                <svg width="24" viewBox="0 0 24 24">
                <path fill="#000" d="M19 13a1 1 0 0 0-1 1v.38l-1.48-1.48a2.79 2.79 0 0 0-3.93 0l-.7.7-2.48-2.48a2.85 2.85 0 0 0-3.93 0L4 12.6V7a1 1 0 0 1 1-1h7a1 1 0 0 0 0-2H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-5a1 1 0 0 0-1-1ZM5 20a1 1 0 0 1-1-1v-3.57l2.9-2.9a.79.79 0 0 1 1.09 0l3.17 3.17 4.3 4.3Zm13-1a.89.89 0 0 1-.18.53L13.31 15l.7-.7a.77.77 0 0 1 1.1 0L18 17.21Zm4.71-14.71-3-3a1 1 0 0 0-.33-.21 1 1 0 0 0-.76 0 1 1 0 0 0-.33.21l-3 3a1 1 0 0 0 1.42 1.42L18 4.41V10a1 1 0 0 0 2 0V4.41l1.29 1.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42Z"/>
                </svg>
            </div>
            <AuthorizedImg class="loadedImg" v-if="!photoPreview && data.file?.source?.original" :src="data.file?.source?.original" />
            <svg v-if="!photoPreview" width="30" viewBox="0 0 448 512">
                <path fill="#414E62" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/>
            </svg>
        </label>
        <p class="mb0">{{data.first_name}} {{data.last_name}}</p>
    </div>

    
</template>
<script>
import AuthorizedImg from '../../components/AuthorizedImg/AuthorizedImg.vue';
import { toast } from 'vue3-toastify';
export default {
    name: "UploadImage",
    props: {
        data: {
            type: Object
        }
    },
    components: {
        AuthorizedImg,
    },
    emits:['uploadImg'],
    data() {
        return {
            photo: null,
            photoPreview: null,
        }
    },
    methods: {
        async setPhoto($event) {
            const file = $event.target.files[0];
            if (!file) {
                return;
            }
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                toast.error('Only JPG, JPEG, and PNG formats are allowed.', {"position": "top-center", autoClose: 2000});
                $event.target.value = ''; 
                return;
            }

            this.photo = file;
            const reader = new FileReader();
            reader.onload = (e) => {
                this.photoPreview = e.target.result; 
            };
            reader.readAsDataURL(file);
            this.$emit('uploadImg', this.photo)
        },
    }, 
}
</script>
<style lang="scss" scoped>
    .upload-image-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            font-size: 18px;
        }

        input {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: -1;
        }
    }

    .upload-image {
        width: 80px;
        aspect-ratio: 1 / 1;
        cursor: pointer;
        background-size: cover;
        background-position: 50% 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid #e4e7ec;
        // overflow: hidden;
        position: relative;
        transition: all .3s ease;

        .upload-image-overlay {
            position: absolute;
            right: -1px;
            top: -1px;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e4e7ec;
            transition: all .3s ease;
            pointer-events: none;
            background-color: #fff;
            border-radius: 50%;

            svg {
                width: 15px;
                transition: all .3s ease;
            }
        }

        .loadedImg {
            object-fit: cover;
            max-width: initial;
            min-width: 100%;
            min-height: 100%;
        }

        &:hover {
            .upload-image-overlay {
                background-color: rgba(#fff,.5);
                backdrop-filter: blur(10px);
                width: calc(100% + 2px);
                height: calc(100% + 2px);

                svg {
                    width: 30px;
                }
            }
        }
    }
</style>