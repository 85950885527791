<template>
    <nav v-if="info && (info.next_page_url || info.prev_page_url)" class="pagination-wrapper" aria-label="...">
        <div class="pagination-btn pagination-btn--prev">
            <button type="button" class="pagination-nav prev button" :disabled="!pages.includes(this.info.current_page - 1)" @click="$emit('page-click', this.info.current_page - 1)">
                <svg fill="none" width="8" viewBox="0 0 8 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m6.5 1-5 5 5 5"/>
                </svg>
            </button>
        </div>
        <ul class="pagination">
            <li @click="$emit('page-click', page)" v-for="(page, key) in pages" :key="key" class="page-item" :class="page === this.info.current_page ? 'active' : ''">
                <span class="page-link">{{page}}</span>
            </li>
        </ul>
        <div class="pagination-btn pagination-btn--next">
            <button type="button" class="pagination-nav next button" :disabled="!pages.includes(this.info.current_page + 1)" @click="$emit('page-click', this.info.current_page + 1)">
                <svg fill="none" width="8" viewBox="0 0 8 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m1.5 1 5 5-5 5"/>
                </svg>
            </button>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Pagination",
    computed: {
        pages() {

            if( ! this.info)
                return []

            let out = [this.info.current_page]

            if(this.info.prev_page_url) {
                out = [this.info.current_page - 1, ...out]
                if(this.fake && this.info.current_page - 2 > 0) {
                    out = [this.info.current_page - 2, ...out]
                }
            }

            if(this.info.next_page_url) {
                out = [...out, this.info.current_page + 1]
                if(this.fake) {
                    out = [...out, this.info.current_page + 2]
                }
            }

            return out.filter(e => !this.ignore.includes(e))
        }
    },
    props: {
        fake: {
            type: Boolean,
            default: false
        },
        ignore: {
            type: Array,
            default: () => []
        },
        info: {
            type: Object,
            default() {
                return {
                    current_page: 1,
                    last_page: 1,
                    next_page_url: null,
                    prev_page_url: null

                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .pagination-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .pagination-btn {
            width: 122px;

            &--next {

                .button {
                    margin-left: auto;
                }
            }
        }

        .pagination-nav {
            width: 36px;
            height: 36px;
            border-radius: 8px;
            background-color: #F2F4F7;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #141C25;
            flex-shrink: 0;

            &:hover {
                background-color: #141C25;
                color: #ffffff;
            }
        }

        .page-item {
            width: 36px;
            height: 36px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #637083;
            cursor: pointer;

            &:hover, &.active {
                background-color: #F2F4F7;
                color: #141C25;
            }
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        gap:8px;
    }
</style>
