<template>
    <main :class="{'h-100': loadPage}">
        <Header></Header>
        <div class="card" v-if="loadPage" :class="{'h-100': loadPage}">
            <div class="card__header" :class="{'h-100': loadPage}">
                <Loader/>
            </div>
        </div>
        
        <div class="card" v-else>
            <div class="card__header o-flex o-flex--center o-flex--justify">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/calendar-frame.svg" alt="Calendar" />
                    </div> 
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Booking</h2>
                    </div>
                </div>
                <a href="javascript:;" class="accordion-trigger" @click="accordionActive = !accordionActive" :class="{'active': accordionActive}">
                    <svg fill="none" width="16" viewBox="0 0 16 16">
                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m4 10 4-4 4 4"/>
                    </svg>
                </a>
            </div>
            <transition name="fade">
                <div class="card__body mt0" v-if="accordionActive">
                    <h2 class="u-font-600">Project information</h2>
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Project <span>*</span></p>
                                <VueMultiselect name="projects" v-model="projectSelected" placeholder="Select one" :options="data" label="name" track-by="name"/>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Phase <span>*</span></p>
                                <VueMultiselect name="phase" v-model="phaseSelected" placeholder="Select one" :options="filteredPhases" label="name" track-by="name">
                                    <template #noResult>
                                        <span>No results</span>
                                    </template>
                                    <template #noOptions>
                                        <span>No phases</span>
                                    </template>
                                </VueMultiselect>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Start Date</p>
                                <div class="date-box input--primary input--disabled">
                                    <div class="icon-wrapper">
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 10.3566V19.3566C3 20.4612 3.89543 21.3566 5 21.3566H19C20.1046 21.3566 21 20.4612 21 19.3566V10.3566M3 10.3566H21M3 10.3566V6.3566C3 5.25203 3.89543 4.3566 5 4.3566H7M21 10.3566V6.3566C21 5.25203 20.1046 4.3566 19 4.3566H18.5M15 4.3566V2.3566M15 4.3566V6.3566M15 4.3566H10.5M7 6.3566V2.3566" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    {{ phaseSelected === null ? '' : formatDate(phaseSelected.start_date) }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>End Date</p>
                                <div class="date-box input--primary input--disabled">
                                    <div class="icon-wrapper">
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 10.3566V19.3566C3 20.4612 3.89543 21.3566 5 21.3566H19C20.1046 21.3566 21 20.4612 21 19.3566V10.3566M3 10.3566H21M3 10.3566V6.3566C3 5.25203 3.89543 4.3566 5 4.3566H7M21 10.3566V6.3566C21 5.25203 20.1046 4.3566 19 4.3566H18.5M15 4.3566V2.3566M15 4.3566V6.3566M15 4.3566H10.5M7 6.3566V2.3566" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    {{ phaseSelected === null ? '' : (phaseSelected.end_date ? formatDate(phaseSelected.end_date): "Ongoing") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!-- <div class="card">
            <div class="card__header o-flex o-flex--center o-flex--justify">
                <h2 class="u-font-600 mb0">Required skills</h2>
                <a href="javascript:;" class="accordion-trigger" @click="accordionActive = !accordionActive" :class="{'active': accordionActive}">
                    <svg fill="none" width="16" viewBox="0 0 16 16">
                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m4 10 4-4 4 4"/>
                    </svg>
                </a>
            </div>
            <div class="card__body pb0 pt0 border-none">
                <div class="table-responsive">
                    <table class="table mb0">
                        <thead>
                            <tr>
                                <th>
                                    Skill
                                </th>
                                <th>
                                    Required Hours
                                </th>
                                <th>
                                    Start Date
                                </th>
                                <th>
                                    End Date
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Frontend
                                </td>
                                <td>
                                    170 h
                                </td>
                                <td>
                                    <vue-date-picker
                                        class="date-table"
                                        :enable-time-picker="false"
                                        auto-apply
                                        :teleport="true"
                                        :model-type="'yyyy-MM-dd'"
                                        :format="'dd.MM.yyyy'"
                                        placeholder="Select Date"
                                        v-model="startDate"
                                        :ui="{ input: 'date-picker' }"
                                    >
                                        <template #input-icon>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </template>
                                    </vue-date-picker>
                                </td>
                                <td>
                                    <vue-date-picker 
                                        class="date-table"
                                        auto-apply
                                        :teleport="true"
                                        :enable-time-picker="false"
                                        :model-type="'yyyy-MM-dd'"
                                        :format="'dd.MM.yyyy'"
                                        placeholder="Select Date"
                                        v-model="endDate"
                                        name="endDate"
                                        :ui="{ input: 'date-picker' }"
                                        :disabled="!startDate"
                                    >
                                        <template #input-icon>
                                             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </template>
                                    </vue-date-picker>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card__footer card--border o-flex o-flex--right pb0 border-none">
                <div class="card__btns o-flex o-flex--center gap-10">
                    <router-link :to="{name: 'project'}" class="button button--secondary">Cancel</router-link>
                    <button type="button" class="button button--primary" >Analyze</button>
                </div>
            </div>
        </div> -->

        <component v-if="componentPayload.length !== 0" :is="currentComponent" :payload="componentPayload"></component>
        <!-- <div class="card">
            <div class="card__header o-flex o-flex--center o-flex--justify">
                <h2 class="u-font-600 mb0">Available Resources</h2>
                <a href="javascript:;" class="accordion-trigger" @click="accordionActive = !accordionActive" :class="{'active': accordionActive}">
                    <svg fill="none" width="16" viewBox="0 0 16 16">
                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m4 10 4-4 4 4"/>
                    </svg>
                </a>
            </div>
            <div class="card__body pt0 border-none">
                <article class="resources-accordion">
                    <div class="accordion-header o-flex o-flex--center">
                        <svg fill="none" viewBox="0 0 20 20" width="20">
                            <path stroke="#97A1AF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.17 7.08 12.08 10l-2.91 2.92m.83 5.41a8.33 8.33 0 1 0 0-16.66 8.33 8.33 0 0 0 0 16.66Z"/>
                        </svg>
                        <p class="mb0">Backend</p>
                        <div class="o-flex o-flex--center info-box-wrapper">
                            <InfoBox :info="'primary'" :text="'170 Hours Total'"/>
                            <InfoBox :info="'secondary'" :text="'170 Hours Allocated'"/>
                            <InfoBox :info="'error'" :text="'170 Hours Total'"/>
                        </div>
                    </div>
                    <div class="accordion-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Employee</th>
                                        <th>Availability</th>
                                        <th>View Skills</th>
                                        <th>Total Hours</th>
                                        <th>Hours <span>(per day)</span></th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>View Projects</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Joca Fitilj</td>
                                        <td>300h</td>
                                        <td>
                                            <a href="javascript:;" class="view-details">
                                                <svg fill="none" width="14" viewBox="0 0 14 10">
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                                </svg>
                                                Details
                                            </a>
                                        </td>
                                        <td>
                                            <input type="text" class="input input--primary" placeholder="Enter">
                                        </td>
                                        <td>
                                            <input type="text" class="input input--primary" placeholder="0-8">
                                        </td>
                                        <td>
                                            <vue-date-picker
                                                class="date-table"
                                                :enable-time-picker="false"
                                                auto-apply
                                                :teleport="true"
                                                :model-type="'yyyy-MM-dd'"
                                                :format="'dd.MM.yyyy'"
                                                placeholder="Select Date"
                                                v-model="startDate"
                                                :ui="{ input: 'date-picker' }"
                                            />
                                        </td>
                                        <td>
                                            <vue-date-picker 
                                                class="date-table"
                                                auto-apply
                                                :teleport="true"
                                                :enable-time-picker="false"
                                                :model-type="'yyyy-MM-dd'"
                                                :format="'dd.MM.yyyy'"
                                                placeholder="Select Date"
                                                v-model="endDate"
                                                name="endDate"
                                                :ui="{ input: 'date-picker' }"
                                                :disabled="!startDate"
                                            />
                                        </td>
                                        <td>
                                            <a href="javascript:;" class="view-details">
                                                <svg fill="none" width="14" viewBox="0 0 14 10">
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                                </svg>
                                                Details
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </article>
                <article class="resources-accordion">
                    <div class="accordion-header o-flex o-flex--center">
                        <svg fill="none" viewBox="0 0 20 20" width="20">
                            <path stroke="#97A1AF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.17 7.08 12.08 10l-2.91 2.92m.83 5.41a8.33 8.33 0 1 0 0-16.66 8.33 8.33 0 0 0 0 16.66Z"/>
                        </svg>
                        <p class="mb0">Frontend</p>
                        <div class="o-flex o-flex--center info-box-wrapper">
                            <InfoBox :info="'primary'" :text="'170 Hours Total'"/>
                            <InfoBox :info="'warning'" :text="'24 Hours Allocated'"/>
                        </div>
                    </div>
                    <div class="accordion-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Employee</th>
                                        <th>Availability</th>
                                        <th>View Skills</th>
                                        <th>Total Hours</th>
                                        <th>Hours <span>(per day)</span></th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>View Projects</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Joca Fitilj</td>
                                        <td>300h</td>
                                        <td>
                                            <a href="javascript:;" class="view-details">
                                                <svg fill="none" width="14" viewBox="0 0 14 10">
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                                </svg>
                                                Details
                                            </a>
                                        </td>
                                        <td>
                                            <input type="text" class="input input--primary error" placeholder="Enter">
                                        </td>
                                        <td>
                                            <input type="text" class="input input--primary" placeholder="0-8">
                                        </td>
                                        <td>
                                            <vue-date-picker
                                                class="date-table"
                                                :enable-time-picker="false"
                                                auto-apply
                                                :teleport="true"
                                                :model-type="'yyyy-MM-dd'"
                                                :format="'dd.MM.yyyy'"
                                                placeholder="Select Date"
                                                v-model="startDate"
                                                :ui="{ input: 'date-picker' }"
                                            />
                                        </td>
                                        <td>
                                            <vue-date-picker 
                                                class="date-table"
                                                auto-apply
                                                :teleport="true"
                                                :enable-time-picker="false"
                                                :model-type="'yyyy-MM-dd'"
                                                :format="'dd.MM.yyyy'"
                                                placeholder="Select Date"
                                                v-model="endDate"
                                                name="endDate"
                                                :ui="{ input: 'date-picker' }"
                                                :disabled="!startDate"
                                            />
                                        </td>
                                        <td>
                                            <a href="javascript:;" class="view-details">
                                                <svg fill="none" width="14" viewBox="0 0 14 10">
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                                </svg>
                                                Details
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </article>
                <article class="resources-accordion">
                    <div class="accordion-header o-flex o-flex--center">
                        <svg fill="none" viewBox="0 0 20 20" width="20">
                            <path stroke="#97A1AF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.17 7.08 12.08 10l-2.91 2.92m.83 5.41a8.33 8.33 0 1 0 0-16.66 8.33 8.33 0 0 0 0 16.66Z"/>
                        </svg>
                        <p class="mb0">QA</p>
                        <div class="o-flex o-flex--center info-box-wrapper">
                            <InfoBox :info="'primary'" :text="'170 Hours Total'"/>
                            <InfoBox :info="'danger'" :text="'0 Hours Allocated'"/>
                        </div>
                    </div>
                    <div class="accordion-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Employee</th>
                                        <th>Availability</th>
                                        <th>View Skills</th>
                                        <th>Total Hours</th>
                                        <th>Hours <span>(per day)</span></th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>View Projects</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Joca Fitilj</td>
                                        <td>300h</td>
                                        <td>
                                            <a href="javascript:;" class="view-details">
                                                <svg fill="none" width="14" viewBox="0 0 14 10">
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                                </svg>
                                                Details
                                            </a>
                                        </td>
                                        <td>
                                            <input type="text" class="input input--primary" placeholder="Enter">
                                        </td>
                                        <td>
                                            <input type="text" class="input input--primary" placeholder="0-8">
                                        </td>
                                        <td>
                                            <vue-date-picker
                                                class="date-table"
                                                :enable-time-picker="false"
                                                auto-apply
                                                :teleport="true"
                                                :model-type="'yyyy-MM-dd'"
                                                :format="'dd.MM.yyyy'"
                                                placeholder="Select Date"
                                                v-model="startDate"
                                                :ui="{ input: 'date-picker' }"
                                            />
                                        </td>
                                        <td>
                                            <vue-date-picker 
                                                class="date-table"
                                                auto-apply
                                                :teleport="true"
                                                :enable-time-picker="false"
                                                :model-type="'yyyy-MM-dd'"
                                                :format="'dd.MM.yyyy'"
                                                placeholder="Select Date"
                                                v-model="endDate"
                                                name="endDate"
                                                :ui="{ input: 'date-picker' }"
                                                :disabled="!startDate"
                                            />
                                        </td>
                                        <td>
                                            <a href="javascript:;" class="view-details">
                                                <svg fill="none" width="14" viewBox="0 0 14 10">
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                                    <path stroke="#0166FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                                </svg>
                                                Details
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </article>
            </div>
        </div> -->
    </main>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import VueMultiselect from 'vue-multiselect';
import InfoBox from '@/components/InfoBox/InfoBox.vue';
import { toast } from 'vue3-toastify';
import Loader from '../../components/Loader/Loader.vue';
import NoData from '@/components/NoData/NoData.vue';
import { getAllProjects } from '../../service/general';
import { getPhaseDetailsApi} from '../../api/projects'; 
import moment from "moment";
import RequiredSkills from './booking-components/RequiredSkills.vue';
import AvailableResources from './booking-components/AvailableResources.vue';


export default {
    name:"BookingProject",
    components: {
        Header,
        VueMultiselect,
        InfoBox,
        Loader,
        NoData,
        RequiredSkills,
        AvailableResources,
    },
    data() {
        return {
            accordionActive: true,
            projectSelected: null,
            phaseSelected: null,
            loadPage: true,
            data: [],
            filteredPhases: [],
            currentComponent: 'RequiredSkills', 
            componentPayload: [],
        }
    },
    
    watch: {
        projectSelected(newValue) {
            this.phaseSelected = null;
            this.componentPayload = [];

            if (newValue && newValue.phases) {
                this.filteredPhases = newValue.phases;
            } else {
                this.filteredPhases = [];
            }
        },

        phaseSelected(newValue) {
            if(newValue !== null) {
                this.singlePhase(newValue)

            }
        }
    },
    methods: {

        formatDate(date) {
            return moment(date).format('DD.MM.YYYY');
        },
        async getProjects() {
            try {
                const data = await getAllProjects();
                this.data = data;
                this.loadPage = false;

            }catch (e) {
                toast.error(e.response, {"position": "top-center", autoClose: 2000});
            }
        },
        async singlePhase(value) {

            try {
                const { data } = await getPhaseDetailsApi(this.projectSelected.id, value.id);
                this.componentPayload = data
            } catch (e) {
                console.log()
                if(e.response.status !== 404) {
                    toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                }
            }
        }
    },
    mounted() {
        this.getProjects();
    },
}
</script>
<style lang="scss" scoped>
    .card__body {
        padding: 24px 0;
        margin-top: 10px;
        border-top: 1px solid #E4E7EC;
    }

    .accordion-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(21, 28, 36, 0.05);
        background-color: #141C25;
        svg {
            transform: rotate(180deg);
            transition: all .3s ease;
        }

        &:hover {
            background-color: darken(#141C25, 10%);
        }
        
        &.active {
            svg {
                transform: rotate(0deg);
            }
        }
    }

    .date-table {
        width: 172px;
    }

    .resources-accordion {
        border-bottom: 1px solid #E4E7EC;
        cursor: pointer;

        p {
            font-weight: 500;
            min-width: 100px;
        }
    }

    .info-box-wrapper {
        gap: 20px;
    }

    .accordion-header {
        gap: 30px;
        padding: 20px;
    }

    .accordion-body {
        margin-left: 40px;
        width: calc(100% - 40px);
        background-color: #F9FAFB;
        border-top: 1px solid #E4E7EC;
        padding: 0 20px 14px;

        .table {
            width: auto;
        }

        td {
            font-weight: 500;
            background-color: #fff;
        }

        th {
            white-space: nowrap;
        }

        tbody {
            tr:last-child {
                td {
                    border-bottom: none;
                }
            }
        }

        .input, .date-table {
            min-width: 140px;
        }
    }

    .view-details {
        padding: 0 6px;
        border-radius: 6px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #344051;
        gap: 6px;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid #E4E7EC;
        background-color: #fff;
    }

    /* --- */
    .card {
        padding: 24px;
        margin-bottom: 16px;

        @media(max-width: 768px) {
            padding: 16px;
        }

        &__header {
            padding: 12px 0;
        }

        &__body {
            margin-top: 24px;
            padding: 24px 0 0;

            @media(max-width: 768px) {
                margin-top: 16px;
            }

            // .input-box {
            //     margin-bottom: 0;
            // }
        }
    }

    .flag {

        &__content {

            p {
                color: var(--light-dark);
                line-height: 20px;
            }
        }
    }
</style>