<template>
  <nav aria-label="breadcrumb">
    <ul class="breadcrumb">
      <li
        v-for="(crumb, index) in breadcrumbs"
        :key="index"
        class="breadcrumb-item"
      >
        <router-link :to="crumb.path" v-if="index !== breadcrumbs.length - 1">
          <svg
            v-if="index === 0"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 17H9M7 21H17C19.2091 21 21 19.2091 21 17V10.7076C21 9.30884 20.2694 8.01172 19.0732 7.28676L14.0732 4.25645C12.7989 3.48418 11.2011 3.48418 9.92679 4.25646L4.92679 7.28676C3.73061 8.01172 3 9.30883 3 10.7076V17C3 19.2091 4.79086 21 7 21Z"
              stroke="#637083"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ crumb.name }}
        </router-link>
        <span v-else class="o-flex o-flex--center"
          ><svg
            v-if="index === 0"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr--"
          >
            <path
              d="M15 17H9M7 21H17C19.2091 21 21 19.2091 21 17V10.7076C21 9.30884 20.2694 8.01172 19.0732 7.28676L14.0732 4.25645C12.7989 3.48418 11.2011 3.48418 9.92679 4.25646L4.92679 7.28676C3.73061 8.01172 3 9.30883 3 10.7076V17C3 19.2091 4.79086 21 7 21Z"
              stroke="#637083"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            /></svg
          >{{ crumb.name }}</span
        >
      </li>
    </ul>
  </nav>
</template>

<script>
import { ref, watch } from 'vue'; // Correct import from 'vue'
import { useRoute } from 'vue-router'; // Import only 'useRoute' from 'vue-router'

export default {
  setup() {
    const route = useRoute();
    const breadcrumbs = ref([]);

    const generateBreadcrumbs = () => {
      let fullPath = '';
      breadcrumbs.value = route.matched.map((r) => {
        fullPath += r.path ? r.path : '';
        return {
          name:
            typeof r.meta.breadcrumb === 'function'
              ? r.meta.breadcrumb(route)
              : r.meta.breadcrumb,
          path: fullPath,
        };
      });
    };

    // Watch for route changes to update breadcrumbs
    watch(route, () => {
      generateBreadcrumbs();
    });

    // Generate breadcrumbs when component is first mounted
    generateBreadcrumbs();

    return {
      breadcrumbs,
    };
  },
};
</script>

<style scoped lang="scss">
.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  margin-right: 12px;

  a {
    color: var(--gray);
    line-height: 1.3;
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
    }
  }
}
.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M6.75 4.5L11.25 9L6.75 13.5" stroke="%23CED2DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
  margin-top: 2px;
}
</style>
